import { GetCart } from 'Commerce/Cart/Cart';
import {
  CHECKOUT_SET_PAYMENT_STEP,
  CHECKOUT_UPDATE_FORM,
  EventDispatcher,
} from 'Shared/Common/EventDispatcher';
import { removeURLParameter, setUrlParameter } from 'Shared/Common/Helpers';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'Theme/stitches.config';
import { useEffect, useState } from 'react';
import CheckoutHeader from './Components/CheckoutHeader/CheckoutHeader';
import CheckoutStepper from './Components/CheckoutSteps/CheckoutSteps';
import OrderSummary from './Components/OrderSummary/OrderSummary';
import Iframe from 'DesignComponents/Organisms/Iframe/Iframe';
import {
  initializePaymentAsync,
  GetPaymentStatus,
} from './Components/CheckoutSteps/Checkout';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import CheckoutPageModel from 'Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import PageLoader from 'DesignComponents/Atoms/Loaders/PageLoader/PageLoader';
import UserAgreementsModel from 'Models/Checkout/CustomerDetails/UserAgreementsModel.interface';
import ContactDetailsModel from 'Models/Checkout/CustomerDetails/ContactDetailsModel.interface';
import ShippingAndBillingAddressModel from 'Models/Checkout/CustomerDetails/ShippingAndBillingAddressModel.interface';
import CheckoutFormDataModel from 'Models/Checkout/CustomerDetails/CheckoutFormDataModel.interface';
import CheckoutPaymentModel from 'Models/Checkout/Payment/CheckoutPaymentModel.interface';
import CheckoutPaymentStatusEnum from 'Models/Checkout/Payment/CheckoutPaymentStatusEnum.interface';
import { IS_DEVELOPMENT_ENV } from 'Shared/Configs/EnvConfig';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';

const STEP_PARAMETER = 'step';
const LANG_ROUTE = IS_DEVELOPMENT_ENV ? 'sv/' : '';

type FormTypes =
  | UserAgreementsModel
  | ContactDetailsModel
  | ShippingAndBillingAddressModel;

function CheckoutPage() {
  const { languageRoute, staticPages } = useAppSettingsData();
  const { cart, isCartEmpty } = GetCart(languageRoute);
  const [paymentStep, setPaymentStep] = useState(false);
  const [paymentError, setPaymentError] = useState<CheckoutPaymentModel | null>(
    null
  );
  const [iFrameHeight, setIFrameHeight] = useState('300px');
  const navigate = useKexNavigate();
  const [checkoutForm, setCheckoutForm] = useState<CheckoutFormDataModel>(
    useCurrentPage<CheckoutPageModel>().checkoutFormDataModel
  );
  const [payment, setPayment] = useState<CheckoutPaymentModel>();
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);

  const pollInterval = 5000;
  let interval: NodeJS.Timeout;

  const params = () => {
    if (!canUseDOM()) return '';
    return window.location.search;
  };

  const selectPayment = (payment: boolean) => {
    if (payment) {
      setUrlParameter(STEP_PARAMETER, 'payment');
      setPaymentStep(true);
    } else {
      if (canUseDOM()) window.location.search = '';
    }
  };

  const updateForm = (key: keyof CheckoutFormDataModel, data: FormTypes) => {
    setCheckoutForm((prev) => {
      return { ...prev, [key]: data };
    });
  };

  const backClick = () => {
    if (canUseDOM()) {
      let url = '';

      if (params().includes(STEP_PARAMETER)) {
        const initialUrl = new URL(window.location.href);
        url = initialUrl.origin + initialUrl.pathname;
      } else {
        url = sessionStorage.getItem('url') as string;
      }

      window.location.href = url;
    }
  };

  const {
    checkoutLabels: { goToPreviousPage, checkout },
  } = useTranslationData();

  const initializePayment = () => {
    initializePaymentAsync(languageRoute, checkoutForm).then(
      (response: CheckoutPaymentModel) => {
        if (
          response.status == CheckoutPaymentStatusEnum.InvalidCustomerDetails
        ) {
          navigate(staticPages.checkoutPage);
          return;
        }

        if (response.status == CheckoutPaymentStatusEnum.Failed) {
          setPaymentError(response);
          setPaymentStep(false);
          removeURLParameter(STEP_PARAMETER);
          return;
        }

        if (response.redirectUrl) {
          navigate(response.redirectUrl);
          return;
        }

        setPayment(response);
      }
    );
  };

  useEffect(() => {
    const postMessage = (e: MessageEvent) => {
      if (typeof e.data === 'string') {
        let data = null;
        try {
          data = JSON.parse(e.data);
        } catch (error: unknown) {
          console.error('Error parsing message data', error);
        }

        if (data && data.egmont) {
          Object.entries(data.egmont).forEach(([name, value]) => {
            switch (name) {
              case 'height':
                setIFrameHeight(value + 'px');
                break;
              case 'redirect': {
                setProcessingPayment(true);

                // Parse the URL
                const oldUrl = new URL(value as string);

                // Create a new URL with the updated hostname
                const newUrl = new URL(
                  LANG_ROUTE + oldUrl.pathname + oldUrl.search + oldUrl.hash,
                  window.location.origin
                );

                fetch(newUrl.toString(), {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })
                  .then((response) => response.json())
                  .catch((error) => {
                    // Handle the error
                    console.error('Error:', error);
                  });
                break;
              }
            }
          });
        }
      }
    };

    window.addEventListener('message', postMessage, false);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', postMessage, false);
    };
  }, []);

  useEffect(() => {
    if (params().includes(STEP_PARAMETER)) {
      setPaymentStep(true);
      setPaymentError(null);
    } else {
      setPaymentStep(false);
    }
  }, [params]);

  useEffect(() => {
    EventDispatcher.subscribe(CHECKOUT_SET_PAYMENT_STEP, selectPayment);
    EventDispatcher.subscribe(CHECKOUT_UPDATE_FORM, updateForm);

    return () => {
      EventDispatcher.unsubscribe(CHECKOUT_SET_PAYMENT_STEP, selectPayment);
      EventDispatcher.unsubscribe(CHECKOUT_UPDATE_FORM, updateForm);
    };
  }, []);

  useEffect(() => {
    if (
      !payment?.paymentGatewayOrderGuid ||
      !payment?.paymentGatewayIframeUrl
    ) {
      clearInterval(interval);
      return;
    }

    interval = setInterval(() => {
      GetPaymentStatus(payment.paymentGatewayOrderGuid).then(
        (response: CheckoutPaymentModel) => {
          switch (response.status) {
            case CheckoutPaymentStatusEnum.Failed:
              clearInterval(interval);
              setPaymentError(response);

              setPaymentStep(false);
              removeURLParameter(STEP_PARAMETER);
              break;

            case CheckoutPaymentStatusEnum.Complete:
              clearInterval(interval);
              navigate(response.redirectUrl);
              break;

            default:
              break;
          }
        }
      );
    }, pollInterval);

    return () => clearInterval(interval);
  }, [payment]);

  useEffect(() => {
    if (paymentStep) initializePayment();

    if (canUseDOM()) {
      window.scrollTo(0, 0);
    }
  }, [paymentStep]);

  useEffect(() => {
    //redirect to start page if cart is empty
    if (cart && isCartEmpty) {
      navigate('/' + LANG_ROUTE);
    }
  }, [cart, isCartEmpty, navigate]);

  return (
    <>
      <CheckoutHeader
        showBackArrow
        backArrowLabel={goToPreviousPage}
        backArrowOnClick={backClick}
      />

      {paymentError && (
        <ContentContainer>
          <ErrorWrapper>
            <Heading tag="h2" size="s" css={{ marginBottom: '10px' }}>
              {paymentError.paymentErrorHeading}
            </Heading>
            <ErrorDescription
              dangerouslySetInnerHTML={{
                __html: paymentError.paymentErrorDescription,
              }}
            />
          </ErrorWrapper>
        </ContentContainer>
      )}

      <CheckoutContainer payment={paymentStep}>
        {!paymentStep ? (
          <>
            <CheckoutPageHeader>{checkout}</CheckoutPageHeader>
            <CheckoutPageHeaderHelper>&nbsp;</CheckoutPageHeaderHelper>
            <CheckoutSteps>
              <CheckoutStepper />
            </CheckoutSteps>
            <CheckoutCart>{cart && <OrderSummary />}</CheckoutCart>
          </>
        ) : payment?.paymentGatewayIframeUrl && !processingPayment ? (
          <Iframe
            src={payment?.paymentGatewayIframeUrl}
            height={iFrameHeight}
          />
        ) : (
          <WaitingForPayment>
            <PageLoader loading={true} fixed={false} />
          </WaitingForPayment>
        )}
      </CheckoutContainer>
    </>
  );
}

export default CheckoutPage;

const maxW = { width: '100%', maxWidth: '500px' };

const CheckoutContainer = styled('div', {
  mt: 4,
  mb: 16,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 32,
  gridTemplateAreas: '"checkoutHeader" "cart" "checkoutSteps"',
  px: '$s100',
  maxW: '$screenMaxWidth',
  minHeight: '300px',
  mx: 'auto',
  '@bpMin376': {
    px: '$s100',
  },
  '@bpMin860': {
    px: '$s100',
    gridTemplateAreas:
      '"checkoutHeader checkoutHeaderHelper" "checkoutSteps cart"',

    gridTemplateRows: '100px 1fr',
    gridTemplateColumns: '1fr 1fr',
  },
  '@bpMin1025': {
    px: '$s400',
    maxWidth: '1000px',
  },
  variants: {
    payment: {
      true: {
        display: 'flex',
      },
    },
  },
});

const CheckoutPageHeader = styled('h1', {
  gridArea: 'checkoutHeader',
  width: '100%',
  mx: 'auto',
  '@bpMax480': {
    ml: 0,
    minWidth: 'auto',
  },
  '@bpMax859': {
    ...maxW,
  },
});

const CheckoutPageHeaderHelper = styled('div', {
  gridArea: 'checkoutHeaderHelper',
  '@bpMax859': {
    display: 'none',
  },
});
const CheckoutSteps = styled('div', {
  gridArea: 'checkoutSteps',
  '@bpMax859': {
    mx: 'auto',
    ...maxW,
  },
});

const CheckoutCart = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  gridArea: 'cart',
  mx: 'auto',
  '@bpMax859': {
    ...maxW,
  },
});

const ErrorWrapper = styled('div', {
  maxWidth: '417px',
  mx: 'auto',
  my: 5,
  p: 4,
  backgroundColor: '$linen',
  border: '1px solid $semanticError',
});

const WaitingForPayment = styled('div', {
  height: 'calc(100vh - 508px);',
  mx: 'auto',
  maxWidth: '300px',
});

const ErrorDescription = styled('div', {
  lineHeight: '$lh24',
});
